import React, { useState } from 'react';
import TechnologyContentHeader from '../TechnologyContentHeader';
import TechnologyContentHighlighter from '../TechnologyContentHighlighter';
import TechnologyContentServices from '../TechnologyContentServices';
import TechnologyContentNextTechnology from '../TechnologyContentNextTechnology';
import './WhyCartusContent.scss';
import { myStyles } from './style';
import clsx from 'clsx';
import RequestFormPopup from '../RequestFormPopup';

const WhyCartusContent = ({ data, pageContext, basePath, images }) => {
  const classes = myStyles(images?.leftDonutBackgroundImage);
  return (
    <div className="whycartus-content-main">
      <div className={clsx('bg-left-donut', classes['bg-left-donut'])}>
        <TechnologyContentHeader
          images={images}
          drodownListData={pageContext}
          basePath={basePath}
          slug={data?.slug}
          headerSectionTitle={data?.headerSectionTile}
          headerSectionDescription={data?.headerSectionDescription?.raw}
          heroImage={data?.heroImage?.file?.url+'?fm=webp&q=100'}
          heroImageDescription={data?.heroImage?.description}
          shortdescription={data?.differentiatorStandAloneIntroSection?.shortDescription?.raw}
          introSectionImage={data?.introSectionImage?.file?.url+'?fm=webp&q=100'}
          description={data?.introSectionImage?.description}
          imageHeader={data?.imageHeader}
          introSection={data?.differentiatorStandAloneIntroSection}
          header={data?.differentiatorStandAloneIntroSection?.header}
          subHeader={data?.differentiatorStandAloneIntroSection?.subHeader}
        />
        <TechnologyContentHighlighter
          highlighterSectionImage={data?.highlighterSectionImage}
          highlighterSectionTitle={data?.highlighterSectionTitle}
        />
        </div>
        <TechnologyContentServices data={data} />
      
      <TechnologyContentNextTechnology
        header={data?.nextDifferentiatorSectionHeader}
        subHeader={data?.nextDifferentiatorSectionSubHeader}
        image={data?.nextDifferentiatorSectionBackgroundImage}
        button={data?.nextDifferentiatorButtonLink}
        NextDifferentiatorHeader="NextDifferentiatorHeader"
        NextDifferentiatorsubHeader="NextDifferentiatorsubHeader"
        NextDifferentiatorBackgroundImage="nextDifferentiatorBackgroundImage"
        ButtonLabel="buttonLabel"
        basePath={basePath}
      />
     <RequestFormPopup data={data?.pardotForm} images={images} />
    </div>
  );
};

export default WhyCartusContent;
