import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import WhyCartusContent from '../components/WhyCartusContent';
import SEO from '../components/seo-component/seo';
//import LoginPage from '../pages/login';
import DialogPopupContext from '../utils/dialogpopup-context';

export default function whycartusContentPage({location, pageContext, data }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };
  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };
  const [open, setOpen] = useState(false);
  const openPopupClick = () => {
    setOpen(true);
  }
  const closePopupClick = () => {
    setOpen(false);
  }
  return (
    <>
      <DialogPopupContext.Provider value={{ status: open, openPopup: openPopupClick, closePopup: closePopupClick}}>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulWhyCartusDifferentiatorStandAlonePage?.seoEntry}
          siteurl={pageContext?.siteurl}
          bucketName={pageContext?.bucketName}
          basePath={pageContext?.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
          videoSEOSchema={data?.contentfulWhyCartusDifferentiatorStandAlonePage?.differentiatorStandAloneIntroSection?.videoComponent?.videoComponent}
        />
        <WhyCartusContent
          data={data?.contentfulWhyCartusDifferentiatorStandAlonePage}
          pageContext={pageContext?.allwhycartusContentData}
          basePath={pageContext?.basePath}
          images={data?.contentfulComponentBackgroundImages}
        />
      </Layout>
      </DialogPopupContext.Provider>
    </>
  );
}

export const query = graphql`
  query whycartusContentTemplate($contentful_id: String, $locale: String) {
    contentfulComponentBackgroundImages {
      capsulesBackgroundImage {
        file {
          url
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      leftDonutBackgroundImage {
        file {
          url
        }
      }
      sys {
        contentType {
          sys {
            type
          }
        }
      }
    }
    contentfulWhyCartusDifferentiatorStandAlonePage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      contentful_id
      slug
      entryTitle
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          file {
            url
          }
        }
      }
      headerSectionTile
      headerSectionDescription {
        raw
      }
      heroImage {
        file {
          fileName
          url
        }
        description
      }
      differentiatorType
      differentiatorName
      differentiatorStandAloneIntroSection {
        entryTitle
        header
        subHeader
        shortDescription {
          raw
        }
        isVideo
        videoComponent {
          videoComponent {
            videoType
            videoTitle
            videoId
            dateOfPublishing(formatString: "YYYY-MM-DD")
            seoEntry {
              metaDescription {
                metaDescription
              }
            }
          }
        }
        image {
          description
          file {
            url
          }
        }
      }
      introSectionImage {
        description
        file {
          fileName
          url
        }
      }
      imageHeader
      highlighterSectionTitle
      highlighterSectionImage {
        description
        file {
          fileName
          url
        }
      }
      serviceContentSectionTitle
      serviceContentFirstRowSubHeader
      serviceContentFirstRowDescription {
        raw
      }
      serviceContentSectionDownloadableDocument {
        downloadImage {
          description
          file {
            fileName
            url
          }
        }
        downloadDocument {
          file {
            fileName
            url
          }
          description
        }
        downloadLink
      }
      serviceContentSectionImage {
        description
        file {
          fileName
          url
        }
      }
      serviceContentSecondRowSubHeader
      serviceContentSecondRowDescription {
        raw
      }
      nextDifferentiatorSectionBackgroundImage {
        description
        file {
          fileName
          url
        }
      }
      nextDifferentiatorSectionHeader {
        raw
      }
      nextDifferentiatorSectionSubHeader {
        raw
      }
      nextDifferentiatorButtonLink {
        label
        link {
          ... on ContentfulWebLink {
            url
          }
          ... on ContentfulInternalLink {
            slug
          }
        }
      }
      pardotForm {
        id
        contentful_id
        entryTitle
        header
        subHeader {
          raw
        }
        iFrameLink
        closeIcon {
          description
          file {
            url
          }
        }
      }
    }
  }
`;
